import React, { useState } from 'react';

const RsvpForm = ({ onClose, onSuccess }) => {

	const [includePlusOne, setIncludePlusOne] = useState(false);
	const [fullName, setFullName] = useState(''); 
	const [plusOneName, setPlusOneName] = useState(''); 
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
	const formData = new FormData();

	onClose(); 

	formData.append('fullName', fullName);
	if (includePlusOne) {
		formData.append('plusOneName', plusOneName);
	}

	console.log('formData',formData);

	try {
		const response = await fetch('https://wedding.finsoftdev.gr/sendmail.php', {
		  method: 'POST',
		  body: formData,
		});
  
		if (!response.ok) throw new Error('Something went wrong with the submission');
		
		// Assume the response is just text
		const result = await response.json();
		if (result.status === 'success') {
                onSuccess('Your RSVP has been confirmed!'); // Pass the success message
        		onClose();
        } else {
            throw new Error(result.message);
        }
	  } catch (error) {
		console.error('Submission error:', error);
		alert('Failed to submit RSVP');
	  }
	};


  const handleCheckbox = () => {
	setIncludePlusOne(!includePlusOne);
  }


  return (
    <>
  <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={onClose}></div>
  <div className="fixed z-50 inset-0 overflow-y-auto">
    <div className="flex items-center justify-center min-h-screen">
      {/* Modal box container with specified background styling */}
      <div className="modal-box bg-white p-8 md:p-24 rounded-lg shadow-lg mx-4" role="dialog" aria-modal="true" aria-labelledby="modal-title">
        {/* Modal content */}
        <h3 id="modal-title" className="text-lg leading-6 font-medium text-gray-900">RSVP to join our special day!</h3>
        <form onSubmit={handleSubmit} className='mt-8'>
              <div className='form-control grid gap-4'>
                <input type="text" placeholder="Full Name" className="input input-bordered w-full max-w-xs" required value = {fullName} onChange={(e) => setFullName(e.target.value)}/>
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <span className="label-text">Include my Plus one</span>
                    <input type="checkbox" className="checkbox" checked={includePlusOne} onClick={handleCheckbox}/>
                  </label>
                </div>
				{ includePlusOne && 
				<input type="text" placeholder="Full Name" className="input input-bordered w-full max-w-xs" required required value = {plusOneName} onChange={(e) => setPlusOneName(e.target.value)} />
				}
                <button type='submit' className="mt-4 w-full max-w-xs cursor-pointer rounded-md bg-gray-800 px-4 py-3 text-center text-sm font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-gray-900">
                  RSVP
                </button>
              </div>
        </form>
        <button onClick={onClose} className="mt-4">Close</button>		
      </div>
	  
    </div>
  </div>
</>

  );
};

export default RsvpForm;
